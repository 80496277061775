<template>
  <div>
    <el-drawer
      title="复制广告组"
      :visible="visible"
      :append-to-body="true"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      size="540px"
      @close="$emit('close-drawer')"
      @opened="open"
    >
      <div class="copy-ad-series-container">
        <el-container>
          <el-main>
            <!--广告系列列表-->
            <div class="ad-series-wrapper">
              <div class="ad-series-title">
                <el-checkbox
                  :indeterminate="checkedAdSeries.length > 0 && checkedAdSeries.length < adSeriesArr.length"
                  :value="checkedAdSeries.length === adSeriesArr.length"
                  @change="handleCheckAllChange"
                  >已选{{ checkedAdSeries.length }}项</el-checkbox
                >
              </div>
              <div class="ad-series-content">
                <el-checkbox-group
                  v-model="checkedAdSeries"
                  @change="handleCheckedAdSeriesChange"
                >
                  <el-checkbox
                    v-for="adSeries in adSeriesArr"
                    :label="adSeries"
                    :key="adSeries.id"
                    :disabled="checkResult && needCheck(adSeries) && !adSeries.$checkResult.pass"
                    :checked="adSeries.checked"
                  >
                    <div class="ad-series-item">
                      <div class="name">{{ adSeries.name }}</div>
                      <!-- 复制状态 -->
                      <span
                        v-loading="results[adSeries.id] ? !results[adSeries.id].done : false"
                        v-if="begin"
                        style="width: 60px; text-align: center"
                      >
                        <!-- 没有错误信息 -->
                        <i
                          class="el-icon-circle-check"
                          v-if="results[adSeries.id] && !results[adSeries.id].hasError"
                          style="color: #67c23a"
                        ></i>
                        <!-- 有错误信息 -->
                        <el-tooltip
                          placement="top-start"
                          width="200"
                          trigger="hover"
                          v-if="results[adSeries.id] && results[adSeries.id].messages"
                        >
                          <p
                            v-for="(message, index) in results[adSeries.id].messages"
                            :key="index"
                            class="resultMessage"
                            slot="content"
                          >
                            <span :class="['point', message.level]"></span>
                            <span>{{ message.message }}</span>
                          </p>
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                      </span>
                      <span
                        v-loading="checking"
                        v-if="checking"
                        >检测中...</span
                      >
                      <div
                        class="status"
                        v-if="checkResult && needCheck(adSeries)"
                      >
                        <i
                          class="el-icon-check"
                          style="color: #47c30f"
                          v-if="adSeries.$checkResult.pass"
                          >成功</i
                        >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="left"
                          v-if="!checking"
                        >
                          <i
                            class="el-icon-close"
                            style="color: #ff0000"
                            v-if="!adSeries.$checkResult.pass"
                            >失败</i
                          >
                          <div
                            slot="content"
                            v-if="adSeries.$checkResult.checkResults.filter((s) => s.level !== 'ok').length > 0"
                          >
                            <div
                              v-for="(res, i) in adSeries.$checkResult.checkResults.filter((s) => s.level !== 'ok')"
                              :key="i"
                            >
                              <i
                                :class="notice[res.level].icon"
                                :style="{ color: notice[res.level].color }"
                              ></i
                              >{{ res.message }}<br />
                            </div>
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <!--复制广告系列的表单-->
            <el-form
              :model="adSeriesFormObj"
              :rules="adSeriesFormRules"
              label-width="120px"
              size="small"
            >
              <!-- 复制到个号 -->
              <el-form-item
                label="复制至"
                prop="toThirdUserId"
                v-if="!isMultiAccountManage"
              >
                <el-cascader
                  placeholder="请选择个号"
                  :options="allUseAdcounts"
                  v-model="thirdIdArr"
                  filterable
                  ref="cascader"
                  size="mini"
                  popper-class="third_user_cascader"
                  :props="{ value: 'id', children: 'thirdLoginUsers', label: 'displayName' }"
                  @change="selectedThirdUser"
                >
                  <template slot-scope="{ node, data }">
                    <span v-if="node.isLeaf">{{
                      `${data.thirdUser.displayName ? data.thirdUser.displayName : data.thirdUser.thirdUserName}${
                        data.thirdUser.thirdUserEmail ? '(' + data.thirdUser.thirdUserEmail + ')' : ''
                      }`
                    }}</span>
                    <span v-else>{{ data.name }}</span>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item
                label="复制至"
                prop="toAccountId"
              >
                <el-select
                  v-model="adSeriesFormObj.toAccountId"
                  @change="chooseCopyToAccount"
                  filterable
                >
                  <el-option-group
                    v-for="group in !notCurrentNum ? accountsOptions : notCurrentNumAccountsOptions"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :disabled="!item.$warn.pass"
                      value-key="id"
                    >
                      <i class="el-icon-info"></i>
                      <span>{{ item.name }}</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.$warn.msg"
                        placement="left"
                        v-if="item.$warn.warn"
                      >
                        <i
                          class="el-icon-warning-outline"
                          :style="{ color: item.$warn.color }"
                        ></i>
                      </el-tooltip>
                      <p class="account-id">ID：{{ item.id }}</p>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item :label="adSeriesFormObj.is_create_target ? '基于系列' : '复制至系列'">
                <el-select
                  v-model="target"
                  clearable
                  filterable
                  placeholder="请输入关键词"
                  :loading="loading"
                  remote
                  :remote-method="remoteMethod"
                  popper-append-to-body
                  style="width: 180px; margin-right: 10px"
                >
                  <!-- {{options}} -->
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="!isInAccountCopy && !item.id && !adSeriesFormObj.is_create_target"
                  >
                  </el-option>
                  <div
                    slot="empty"
                    class="empty"
                  >
                    <i class="iconfont icon-wushuju"></i>
                    <p>暂无数据</p>
                  </div>
                </el-select>
                <el-checkbox
                  v-model="adSeriesFormObj.is_create_target"
                  v-if="level == 'adset'"
                  >创建新系列</el-checkbox
                >

                <!-- <span>当前 {{levels[level].parent}}</span> -->
              </el-form-item>
              <el-form-item
                label="复制份数"
                prop="copys"
              >
                <el-input
                  v-model="adSeriesFormObj.copys"
                  type="number"
                  max="30"
                  min="1"
                  :inline="true"
                  style="width: 100px; margin-right: 10px"
                ></el-input>
                <span>最大30份</span>
              </el-form-item>
              <el-form-item
                label="主页"
                prop="targetPageId"
              >
                <el-select
                  v-model="adSeriesFormObj.targetPageId"
                  placeholder="请选择主页"
                  @change="selectPage"
                  filterable
                  clearable
                >
                  <el-option
                    :label="page.name"
                    :value="page.id"
                    style="height: 70px"
                    v-for="page in accountPagesList"
                    :key="page.id"
                    :disabled="!page.is_published"
                  >
                    <div class="pageItems">
                      <div class="pageImg">
                        <el-image :src="page.picture_url"></el-image>
                      </div>
                      <div class="pageMsg">
                        <p>
                          <el-tooltip
                            content="检测到提示主页已经被封"
                            v-if="page.isBlocked"
                            ><i
                              class="el-icon-lock"
                              style="color: #f00"
                            ></i
                          ></el-tooltip>
                          <!-- <el-tooltip :content="`当前主页在投放中/审核中广告数量：${page.adsRunningCount}`"><span :style="{'color':`${page.adsRunningCount>200?'#f00':'#67C23A'}`}">（{{'#'+page.adsRunningCount}}）</span></el-tooltip> -->
                          {{ page.name }}
                        </p>
                        <div>编号：{{ page.id }}</div>
                      </div>
                    </div>
                  </el-option>
                  <div
                    slot="empty"
                    class="empty"
                    v-loading="pageLoading"
                  >
                    <i class="iconfont icon-wushuju"></i>
                    <p>暂无数据</p>
                  </div>
                </el-select>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="强制刷新主页，清除本地缓存主页数据"
                  placement="top"
                >
                  <el-button
                    icon="el-icon-refresh"
                    @click="getPage(adSeriesFormObj.toAccountId, true)"
                    type="text"
                    size="mini"
                    :disabled="pageLoading"
                  ></el-button>
                </el-tooltip>
                <span
                  class="item"
                  style="font-size: 12px; color: #999"
                  v-show="adSeriesFormObj.targetPageId"
                >
                  总容量：{{ limit_on_ads_running_or_in_review }}，已使用：{{ ads_running_or_in_review_count }}
                </span>
              </el-form-item>
              <el-form-item
                label="像素"
                prop="targetPixelId"
              >
                <el-select
                  v-model="adSeriesFormObj.targetPixelId"
                  placeholder="请选择像素"
                  @change="checkResult = null"
                >
                  <el-option
                    :label="page.name"
                    :value="page.pixel_id"
                    v-for="page in pixelList"
                    :key="page.id"
                  >
                    {{ page.name }}
                  </el-option>
                  <div
                    slot="empty"
                    class="empty"
                    v-loading="pixelLoading"
                  >
                    <i class="iconfont icon-wushuju"></i>
                    <p>暂无数据</p>
                  </div>
                </el-select>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="强制刷新主页，清除本地缓存主页数据"
                  placement="top"
                >
                  <el-button
                    icon="el-icon-refresh"
                    @click="getPixel(adSeriesFormObj.toAccountId, true)"
                    type="text"
                    size="mini"
                    :disabled="pixelLoading"
                  ></el-button>
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label="关联商品"
                prop="saleRelationType"
              >
                <el-radio-group
                  v-model="saleRelationType"
                  @change="selectSaleType"
                >
                  <p
                    v-for="item in ECSaleRelationType"
                    class="saleType"
                  >
                    <el-radio :label="item.name">{{ item.desc }}</el-radio>
                  </p>
                </el-radio-group>
                <!-- <p v-for="item in ECSaleRelationType">
                  <el-radio
                    v-model="saleRelationType"
                    :label="item.name"
                    >{{ item.desc }}</el-radio
                  >
                </p> -->
              </el-form-item>
              <!-- 跨张号复制需要信息 -->
              <el-form-item
                label="推广链接"
                prop="targetUrl"
                @change="checkResult = null"
                v-if="saleRelationType === 'assign_sale'"
              >
                <!-- <el-input v-model="adSeriesFormObj.targetUrl"></el-input> -->
                <link-autocomplete @linkInfo="linkInfo" />
              </el-form-item>
              <el-form-item
                label="网域"
                prop="targetUrl"
                @change="checkResult = null"
                v-if="saleRelationType === 'assign_sale'"
              >
                <!-- <el-input v-model="adSeriesFormObj.conversion_domain" placeholder="网域仅包含一级域和二级域，例如：giikin.com"></el-input> -->
                <web-domain
                  :initWebDomain="adSeriesFormObj.conversion_domain"
                  :webDomains="webDomains"
                  @select-change="selectChange"
                ></web-domain>
              </el-form-item>
              <add-sale
                :alwaysShowColl="true"
                :saleRelationType="saleRelationType"
                :size="true"
                :msg="saleInfoData"
                @saleInfo="saleInfo"
                ref="saleInfo"
              ></add-sale>
              <el-form-item
                label="复制后状态"
                prop="adStatus"
              >
                <!-- <el-radio-group v-model="adSeriesFormObj.isUpload"  v-if="!isInAccountCopy">
                        <el-radio-button :label="false">存入草稿箱</el-radio-button>
                        <el-radio-button :label="true">上传至Facebook</el-radio-button>
                      </el-radio-group> -->
                <el-radio-group v-model="adSeriesFormObj.adStatus">
                  <el-radio label="ACTIVE">开启</el-radio>
                  <el-radio label="PAUSED">暂停</el-radio>
                  <el-radio label="ONLY_ADSET_PAUSED">只暂停广告组</el-radio>
                  <el-radio label="CAMPAIGN_ACTIVE">系列开启（组、广告维持原状） </el-radio>
                </el-radio-group>
                <div>
                  复制后的广告系列投放状态为{{
                    adSeriesFormObj.adStatus == 'CAMPAIGN_ACTIVE'
                      ? '系列开启（组、广告维持原状)'
                      : adSeriesFormObj.adStatus == 'PAUSED'
                      ? '暂停'
                      : adSeriesFormObj.adStatus == 'ONLY_ADSET_PAUSED'
                      ? '系列开启（组暂停，广告开启）'
                      : '开启'
                  }}
                </div>
              </el-form-item>
              <el-form-item label="名称后缀：">
                <el-checkbox v-model="isSetSubName">是否设置名称后缀</el-checkbox>
              </el-form-item>
              <el-form-item
                label=""
                prop="targetUrl"
                v-if="!notCurrentNum"
              >
                <ad-mood
                  v-model="adSeriesFormObj.show_mood"
                  :disabled="saleRelationType != 'default_sale'"
                ></ad-mood>
              </el-form-item>
            </el-form>
          </el-main>
          <el-footer>
            <el-row
              type="flex"
              justify="end"
            >
              <span>
                <el-button @click="$emit('close-drawer')">取消</el-button>
                <el-button
                  type="primary"
                  @click="campaignsCheck"
                  v-show="!canCopy"
                  >检测</el-button
                >
                <el-button
                  type="primary"
                  @click="confirmCopy"
                  v-show="canCopy"
                  >直接复制</el-button
                >
                <el-button
                  type="primary"
                  @click="nextStep"
                  v-show="canCopy"
                  :disabled="can"
                  >下一步，编辑</el-button
                >
              </span>
            </el-row>
          </el-footer>
        </el-container>
      </div>
      <edit-copy
        :batchEditShow.sync="batchEditShow"
        :list="checkedAdSeries"
        :initCreatAdData="initDataAdCreat"
        @get-list="$emit('reload-table')"
        :params="params"
        level="adset"
      ></edit-copy>
      <direct-copy
        :creatForm.sync="creatFormShow"
        :params="params"
        level="adset"
      ></direct-copy>
    </el-drawer>
  </div>
</template>
<script>
import webDomain from '@/views/adManagement/createAd/components/webDomain';
import directCopy from '@/views/adManagement/components/editCopy/components/directCopy';
import mixWebDomain from '@/views/adManagement/assets/js/webDomain';
import _ from 'lodash';
import {
  copyCampaignsCheck,
  copyCampaigns,
  adaccoutsHeader,
  accountPagesHeader,
  accountPixelHeader,
} from '@/api/adManagement.js';
import { historyInput } from '@/api/creatAd.js';
import { copyAd, report, pageVolumes } from '@/api/adManagement';
import { fnCSTToYmd } from '@/utils/utils';
// import {accountPages,accountPixel} from '@/api/creatAd.js'
import copyProcess from '../../copy/copyProcess';
import editCopy from './edit';
import adMood from './adMood';
import addSale from '../../../createAd/components/addSale';
import { mapState, mapGetters } from 'vuex';
import { domainReg } from '@/utils/utils';
import linkAutocomplete from './linkAutocomplete.vue';
import getMultiAccountList from './getMultiAccountList.js';
export default {
  props: ['visible', 'editRows', 'level'],
  mixins: [copyProcess, mixWebDomain, getMultiAccountList],
  components: {
    editCopy,
    adMood,
    addSale,
    webDomain,
    linkAutocomplete,
    directCopy,
  },
  data() {
    var checkUrl = (rule, value, callback) => {
      let reg = new RegExp(/^((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/);
      if (value && !reg.test(value)) {
        callback(new Error('请输入正确的网址'));
      } else {
        callback();
      }
    };
    return {
      thirdIdArr: [], //级联选择下拉框数组
      pageLoading: false,
      pixelLoading: false,
      showCopyAdSeries: false, // 是否展示复制广告系列的弹窗
      isIndeterminate: false, // 是否选中全部(只选中样式)
      checkedAdSeries: [], // 已选中的广告系列
      adSeriesArr: [], // 全部广告系列的数组
      // 复制广告系列的表单
      adSeriesFormObj: {
        toThirdUserId: '',
        toAccountId: '',
        copys: 1,
        adStatus: 'ACTIVE',
        isUpload: true,
        show_mood: false,
        conversion_domain: '',
      },
      isSetSubName: false, //是否设置名称后缀
      // 复制广告系列的表单验证
      adSeriesFormRules: {
        toAccountId: [
          {
            required: true,
            message: '请选择需要复制到哪个账户',
            trigger: 'blur',
          },
        ],
        copys: [{ required: true, message: '请输入复制的份数', trigger: 'blur' }],
        adStatus: [{ required: true, message: '请选择复制后的状态', trigger: 'blur' }],
        targetPageId: [{ required: false, message: '选择主页', trigger: 'blur' }],
        targetPixelId: [{ required: false, message: '选择像素', trigger: 'blur' }],
        targetUrl: [{ validator: checkUrl, trigger: 'blur' }],
      },
      // 账户列表
      accountsList: [],
      currentAccount: {},
      accountPagesList: [],
      pixelList: [],
      checkResult: null,
      checking: false,
      notice: {
        error: {
          icon: 'el-icon-circle-close',
          color: '#ff0000',
        },
        warn: {
          icon: 'el-icon-warning-outline',
          color: '#faad14',
        },
        ok: {
          icon: 'el-icon-circle-check',
          color: '#47c30f',
        },
      },
      begin: false,
      results: {},
      flag: false,
      flag1: false,
      flag2: false,
      // 个号相关
      currentNum: {},
      notCurrentNum: false,
      // 不是当前个号下的广告账号列表
      notCurrentNumAccounts: [],
      // 创建广告常量参数
      initDataAdCreat: this.$store.getters.initData,
      batchEditShow: false,
      params: null,
      domainHistory: [],
      target: '',
      loading: false,
      options: [],
      optionsDef: [],
      adParams: {
        level: 'campaign',
        keyword: '',
        account_id: this.accountId,
        object_filtering: [],
        fields: ['name', 'id'],
        action_attribution_windows: [],
        filtering: [],
        date_start: '',
        date_end: '',
      },
      levels: {
        adset: {
          desc: '广告组',
          parent: '广告系列',
        },
        ad: {
          desc: '广告',
          parent: '广告组',
        },
      },
      targetAdset: [],
      saleRelationType: 'default_sale',
      saleInfoData: {}, // 商品信息
      limit_on_ads_running_or_in_review: 0, // 主页总投放
      ads_running_or_in_review_count: 0, // 主页已投放
      creatFormShow: false,
    };
  },
  computed: {
    ...mapState('num', ['selectedNum', 'numLists']),
    ...mapGetters('num', ['validNumLists']),
    numList() {
      let list = [...this.validNumLists];
      list.map((v) => {
        v.name = v.thirdUser.thirdUserName;
      });
      return list.sort(function (a, b) {
        return a.name.localeCompare(b.name, 'zh');
      });
    },
    selectNumObj() {
      return this.numLists.find((item) => item.id == this.selectedNum);
    },
    allUseAdcounts() {
      this.init();
      return this.$store.state.initData.allUseAdcounts;
    },
    ECSaleRelationType() {
      return this.$store.state.initData.initData?.ad?.ECSaleRelationType || [];
    },
    isInAccountCopy() {
      if (!this.notCurrentNum) {
        return this.adSeriesFormObj.toAccountId === this.currentAccount.id;
      } else {
        return false;
      }
    },

    numsOptions() {
      this.init();
      return [
        {
          label: '当前个号',
          options: this.validNumLists.filter((item) => item.id == this.currentRowNum),
        },
        {
          label: '其他个号',
          options: this.validNumLists.filter((item) => item.id != this.currentRowNum),
        },
      ];
    },
    notCurrentNumAccountsOptions() {
      return [
        {
          label: '广告账号',
          options: this.notCurrentNumAccounts.map((it) => {
            it.$warn = this.accountWarn(it);
            return it;
          }),
        },
      ];
    },
    accountsOptions() {
      this.currentAccount.$warn = this.accountWarn(this.currentAccount);
      return [
        { label: '当前广告账号', options: [this.currentAccount] },
        {
          label: '其他广告账号',
          options: this.accountsList
            .filter((item) => item.id !== this.currentAccount.id)
            .map((it) => {
              it.$warn = this.accountWarn(it);
              return it;
            }),
        },
      ];
    },
    canCopy() {
      //   if (this.isInAccountCopy) return true;
      //   if (!this.checkResult) return false;
      //   if (this.checkedAdSeries.some((adSeries) => !adSeries.$checkResult.pass))
      //     return false;
      return true;
    },
    // 多账号 currentNum 当前个号
    currentRowNum() {
      if (this.checkedAdSeries && this.checkedAdSeries.length && this.checkedAdSeries[0].rowThirdNum) {
        return this.checkedAdSeries[0].rowThirdNum;
      } else {
        return this.selectedNum;
      }
    },
    accountId() {
      if (this.checkedAdSeries && this.checkedAdSeries.length && this.checkedAdSeries[0].rowAccountId) {
        return this.checkedAdSeries[0].rowAccountId;
      } else {
        return this.$store.getters.accountId;
      }
    },
    isMultiAccountManage() {
      console.log(this.$route.path);
      return this.$route.path == '/ad/multiAccountManageIndex';
    },
    rowCurrentAccount() {
      let currentLabel = localStorage.getItem('multiAccountLabel')
        ? localStorage.getItem('multiAccountLabel')
        : this.adAccountLabelList[0].id;
      let list = this.adAccountLabelList.find((v) => v.id == currentLabel).list;
      console.log(list, this.accountId);
      console.log(list.find((k) => k.id == this.accountId));
      return list.find((k) => k.id == this.accountId);
    },
  },
  created() {},
  methods: {
    selectedThirdUser(val) {
      console.log(val);
      this.adSeriesFormObj.toThirdUserId = val[val.length - 1];
      this.chooseCopyToNum(val[val.length - 1]);
    },
    linkInfo(data, url) {
      this.adSeriesFormObj.targetUrl = url;
      this.saleInfoData = data;
    },
    selectChange(val) {
      let notNewAdd = this.apiWebDomains.includes(val);
      if (!notNewAdd) {
        let reg = /([0-9a-zA-Z-_]+\.\w{2,10})($|\/|\?)/;
        let pass = reg.test(val);
        if (pass) {
          let el = val.match(reg)[1];
          this.iptWebDomains.push(el);
          this.iptWebDomains = this.sortUniq(this.iptWebDomains);
          this.adSeriesFormObj.conversion_domain = val;
        } else {
          this.adSeriesFormObj.conversion_domain = '';
        }
      } else {
        this.adSeriesFormObj.conversion_domain = val;
      }
    },
    // 获取广告账户列表
    adaccouts(id) {
      let params = {
        keyword: '',
        limit: 10000,
        page: 1,
        id,
      };
      this.$showLoading();
      return adaccoutsHeader(params).then((res) => {
        this.$hideLoading();
        if (res.code == 200) {
          this.notCurrentNumAccounts = res.data.list && res.data.list.length ? [...res.data.list] : [];
        }
      });
    },
    // 远程搜索
    remoteMethod(query) {
      this.loading = true;
      let end = new Date(),
        start = new Date().getFullYear() + '/' + (new Date().getMonth() / 1 + 1) + '/' + new Date().getDate();
      // start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
      this.adParams.date_start = fnCSTToYmd(new Date(start), { tag: 1 });
      this.adParams.date_end = fnCSTToYmd(end, { tag: 1 });
      this.adParams.keyword = query;
      this.adParams.action_attribution_windows = [];
      this.adParams.filtering = [];
      this.adParams.fields = ['name', 'id'];
      this.adParams.object_filtering = [];
      this.adParams.account_id = this.adSeriesFormObj.toAccountId;
      this.adParams.level = this.level == 'adset' ? 'campaign' : 'adset';
      report(JSON.stringify(this.adParams), this, this.adSeriesFormObj.toThirdUserId).then((res) => {
        // console.log(res.data.data);
        this.loading = false;
        this.options = this.optionsDef.concat(res.data.data);
      });
    },
    selectAdset(v) {
      console.log(v);
      if (v.includes('-1')) {
        this.target = this.options.map((k) => {
          if (k.id != -1) {
            return k.id;
          }
        });
      }
      // if(v == )
    },
    messages(msg) {
      if (msg) {
        return msg.messages;
      } else {
        return [];
      }
    },
    needCheck(campaign) {
      return this.checkedAdSeries.some((f) => f.id === campaign.id);
    },
    campaignCheckResult(campaign) {
      if (this.checking) {
        return {
          checkResults: [
            {
              level: 'error',
              message: '检测中',
            },
          ],
          pass: false,
        };
      }
      let res = {
        checkResults: [],
        pass: true,
      };
      if (!this.checkResult) return res;
      if (this.checkResult.accountCheckResult) {
        res = this.checkResult.accountCheckResult;
        if (!res.pass) {
          return res;
        }
      }
      let campaignRes = this.checkResult.checkResultMap[campaign.id];
      res.checkResults = res.checkResults.concat(campaignRes.checkResults);
      res.pass = campaignRes.pass;
      return res;
    },
    getAccountList() {
      let list = [];
      this.adAccountLabelList.forEach((item) => {
        list = _.unionBy(list, item.list, 'id');
      });
      return this.isMultiAccountManage ? list : this.$store.getters.accountsList;
    },
    open() {
      this.showCopyAdSeries = true;
      this.adSeriesFormObj.show_mood = false;
      this.init();
      this.notCurrentNum = false;
      this.accountsList = _.cloneDeep(this.getAccountList());
      this.currentAccount = this.isMultiAccountManage
        ? _.cloneDeep(this.rowCurrentAccount)
        : _.cloneDeep(this.$store.getters.currentAccountInfo);
      this.getAcoountPages(this.accountId, false);
      historyInput({ accountId: this.accountId, type: 'CONVERSION_DOMAIN' }, this.adSeriesFormObj.toThirdUserId).then(
        (res) => {
          this.domainHistory = res.data;
        },
      );
    },
    accountWarn(account) {
      let res = {
        pass: true,
        warn: false,
        msg: '通过',
        color: '#47c30f',
      };
      if (account.accountStatus === 2) {
        res.pass = false;
        res.warn = true;
        res.msg = '广告账户不可用';
        res.color = '#ff0000';
      } else if (account.timezoneName !== this.currentAccount.timezoneName) {
        res.msg = '广告账户时区不一致';
        res.warn = true;
        res.color = '#faad14';
      } else if (account.currency && account.currency.key !== this.currentAccount.currency.key) {
        res.msg = '广告账户币种不一致';
        res.warn = true;
        res.color = '#faad14';
      }
      return res;
    },
    // 选择商品类型
    selectSaleType(v) {
      console.log(v);
      if (v != 'assign_sale') {
        console.log(1111111);
        this.adSeriesFormObj.sale = null;
        this.adSeriesFormObj.site = null;
        this.adSeriesFormObj.targetUrl = null;
      }
    },
    // 获取商品信息
    saleInfo(v) {
      this.adSeriesFormObj = { ...this.adSeriesFormObj, ...v };
    },
    getParams() {
      let params = {
        source_third_user_id: this.currentRowNum, //当前个号id
        target_third_user_id: this.isMultiAccountManage ? null : this.adSeriesFormObj.toThirdUserId, //目标个号id
        source_account_id: this.accountId, //当前账号id
        target_account_id: this.adSeriesFormObj.toAccountId, //目标账号id
        source_obj_level: this.level === 'ad' ? 'AD' : 'ADSET', //当前级别
        target_obj_level: this.level === 'ad' ? 'ADSET' : 'CAMPAIGN', //目标级别
        source_obj_ids: this.checkedAdSeries.map((v) => v.id), //当前的id
        target_obj_id: this.level === 'ad' ? this.target.join(',') : this.target, //目标级别的id
        ad_status: this.adSeriesFormObj.adStatus,
        show_mood: this.adSeriesFormObj.show_mood,
        copys: this.adSeriesFormObj.copys,
        is_create_target: this.adSeriesFormObj.is_create_target,
        isSetSubName: this.isSetSubName,
        replace_option: {
          targetPageId: this.adSeriesFormObj.targetPageId || null,
          targetPixelId: this.adSeriesFormObj.targetPixelId || null,
          targetUrl: this.adSeriesFormObj.targetUrl || null,
          sale: this.saleRelationType == 'assign_sale' ? this.adSeriesFormObj.sale : null,
          site: this.adSeriesFormObj.site,
          conversion_domain: this.adSeriesFormObj.conversion_domain,
          sale_relation_type: this.saleRelationType,
        },
      };
      this.params = JSON.parse(JSON.stringify(params));
    },
    //   点击进行下一步编辑
    nextStep() {
      if (!this.checkedAdSeries.length) {
        this.$message({
          type: 'warning',
          message: '请至少选择一条数据',
        });
        return false;
      }
      // if (!this.adSeriesFormObj.sale && this.notCurrentNum) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请选择商品信息',
      //   });
      //   return false;
      // }
      if (this.$refs.saleInfo.errorGoods) {
        this.$message({
          type: 'warning',
          message: this.$refs.saleInfo.text,
        });
        return;
      }
      if (!this.adSeriesFormObj.site && this.notCurrentNum) {
        this.$message({
          type: 'warning',
          message: '请选择站点信息',
        });
        return false;
      }
      // if(!this.adSeriesFormObj.conversion_domain){
      //   this.$message({
      //     type:'warning',
      //     message:'请输入正确的网域信息'
      //   })
      //   return false;
      // }
      this.getParams();
      this.batchEditShow = true;
    },
    confirmCopy() {
      if (!this.checkedAdSeries.length) {
        this.$message({
          type: 'warning',
          message: '请至少选择一条数据',
        });
        return false;
      }
      if (this.$refs.saleInfo.errorGoods) {
        this.$message({
          type: 'warning',
          message: this.$refs.saleInfo.text,
        });
        return;
      }
      // if (!this.adSeriesFormObj.sale && this.notCurrentNum) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请选择商品信息',
      //   });
      //   return false;
      // }
      if (!this.adSeriesFormObj.site && this.notCurrentNum) {
        this.$message({
          type: 'warning',
          message: '请选择站点信息',
        });
        return false;
      }
      this.getParams();
      this.creatFormShow = true;
    },
    campaignsCheck() {
      this.checking = true;
      this.checkResult = null;
      const data = {
        campaignIds: [],
        adStatus: this.adSeriesFormObj.adStatus,
        accountId: this.accountId,
        copys: this.adSeriesFormObj.copys,
        toThirdUserId: this.adSeriesFormObj.toThirdUserId,
        toAccountId: this.adSeriesFormObj.toAccountId,
        replaceOption: {
          targetPageId: this.adSeriesFormObj.targetPageId,
          targetPixelId: this.adSeriesFormObj.targetPixelId,
          targetUrl: this.adSeriesFormObj.targetUrl,
          site: this.adSeriesFormObj.site,
        },
      };
      if (this.checkedAdSeries.length == 0) {
        this.$message.error('请至少选择一个广告系列');
        return false;
      }
      this.checkedAdSeries.forEach((item) => {
        data.campaignIds.push(item.id);
      });
      // this.$showLoading()
      copyCampaignsCheck(JSON.stringify(data), this.currentRowNum).then((res) => {
        if (res.code == 0) {
          this.checkResult = res.data;
          this.checking = false;
          this.checkedAdSeries.forEach((camp) => {
            camp.$checkResult = this.campaignCheckResult(camp);
          });
        } else {
          this.checkResult = null;
          this.checking = false;
        }
      });
    },
    closeCopyAdSeries() {},
    // 全选
    handleCheckAllChange(v) {
      // console.log(v)
      this.checkedAdSeries = v ? this.adSeriesArr : [];
    },
    // 广告系列改变
    handleCheckedAdSeriesChange(v) {},
    getPage(id, force) {
      this.pageLoading = true;
      this.flag1 = false;
      accountPagesHeader({ accountId: id, force: force }, this.adSeriesFormObj.toThirdUserId).then((res) => {
        // console.log(res);
        let canUse = res.data.filter((v) => v.is_published);
        let notUse = res.data.filter((v) => !v.is_published);
        this.accountPagesList = canUse.concat(notUse);
        this.flag1 = true;
        this.pageLoading = false;
        if (force) {
          this.$message('更新成功');
        }
      });
    },
    getPixel(id, force) {
      this.pixelLoading = true;
      this.flag2 = false;
      accountPixelHeader({ accountId: id, force: force }, this, this.adSeriesFormObj.toThirdUserId).then((res) => {
        // console.log(res);
        this.pixelList = res.data;
        this.flag2 = true;
        this.pixelLoading = false;
        if (force) {
          this.$message('更新成功');
        }
      });
    },
    async getAcoountPages(id, force) {
      await this.getPage(id, force);
      await this.getPixel(id, force);
      this.flag = this.flag1 && this.flag2;
    },
    // 初始化
    init() {
      // console.log("this.editRows",this.editRows)
      this.adSeriesArr = _.cloneDeep(this.editRows);
      // console.log(this.adSeriesArr);
      this.checking = false;
      this.checkResult = null;
      this.target = '';
      this.checkedAdSeries = this.adSeriesArr;
      this.optionsDef = [{ name: `当前${this.levels[this.level].parent}`, id: null }];
      this.options = this.optionsDef;
      // console.log('22222');
      this.accountsList = _.cloneDeep(this.$store.getters.accountsList);
      this.currentAccount = this.isMultiAccountManage
        ? _.cloneDeep(this.rowCurrentAccount)
        : _.cloneDeep(this.$store.getters.currentAccountInfo);
      if (this.selectedNum) {
        let statusId = this.selectNumObj.status == 'VALID' ? 1 : 2;
        let selectNumLable = (this.selectNumLable || localStorage.getItem('selectNumLable')) / 1 || statusId;
        this.thirdIdArr = [selectNumLable, this.selectedNum];
      }
      this.adSeriesFormObj = {
        toThirdUserId: this.currentRowNum,
        toAccountId: this.currentAccount.id,
        copys: 1,
        adStatus: 'ACTIVE',
        isUpload: false,
      };
    },
    checkedItem(item) {
      item.checked = true;
    },
    // 选择要复制到的个号
    async chooseCopyToNum(v) {
      this.currentNum = this.allUseAdcounts.find((item) => item.id == v);
      this.$set(this.adSeriesFormObj, 'toAccountId', '');
      this.$set(this.adSeriesFormObj, 'targetPageId', '');
      this.$set(this.adSeriesFormObj, 'targetPixelId', '');
      this.accountPagesList = [];
      this.pixelList = [];
      if (v != this.currentRowNum) {
        // console.log("选中的不是当前个号！")
        this.notCurrentNum = true;
        await this.adaccouts(v);
      } else {
        this.adSeriesFormObj.toAccountId = this.currentAccount.id;
        this.notCurrentNum = false;
      }
    },
    // 选择要复制到的账号,判断选中的账号是否需要进行检测
    chooseCopyToAccount(v) {
      // console.log(v);
      this.$set(this.adSeriesFormObj, 'targetPageId', '');
      this.$set(this.adSeriesFormObj, 'targetPixelId', '');
      this.flag = false;
      this.checkResult = null;
      this.accountPagesList = [];
      this.pixelList = [];
      this.options = this.optionsDef;
      this.target = '';
      this.getAcoountPages(this.adSeriesFormObj.toAccountId, false);
    },
    // 点击选择主页
    selectPage(v) {
      this.checkResult = null;
      let params = {
        pageId: v,
        accountId: this.accountId,
      };
      pageVolumes(params, this.rowThirdNum).then((res) => {
        console.log(res);
        const { limit_on_ads_running_or_in_review, ads_running_or_in_review_count } = res.data;
        this.limit_on_ads_running_or_in_review = limit_on_ads_running_or_in_review;
        this.ads_running_or_in_review_count = ads_running_or_in_review_count;
      });
    },
  },
  watch: {
    'adSeriesFormObj.targetPixelId': {
      handler(val) {
        let curWebDomain = this.adSeriesFormObj.conversion_domain;
        let isUrl = [this.urlWebDomains].includes(curWebDomain);
        let isIpt = this.iptWebDomains.includes(curWebDomain);
        console.log('isUrl,isIpt', isUrl, isIpt);
        if (!isUrl && !isIpt) {
          this.$set(this.adSeriesFormObj, 'conversion_domain', '');
        }
        if (val) {
          this.domainsByFbpixels(val);
        }
      },
    },
    'adSeriesFormObj.targetUrl': {
      handler(val) {
        if (!val) {
          this.urlWebDomains = '';
          this.adSeriesFormObj.conversion_domain = '';
        } else {
          let reg = /^((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/;
          if (reg.test(val)) {
            let el = '';
            if (val.match(domainReg) && val.match(domainReg)[0]) {
              el = val.match(domainReg)[0];
            }
            // this.$set(this.adSeriesFormObj,'conversion_domain',el)
            // this.urlWebDomains=el
          } else {
            this.$set(this.adSeriesFormObj, 'conversion_domain', '');
            this.urlWebDomains = '';
          }
        }
      },
    },
    isSetSubName(val) {
      // 本地记录用户操作
      if (val) {
        localStorage.setItem('adset-copy-set-sub-name', 1);
      } else {
        localStorage.setItem('adset-copy-set-sub-name', 0);
      }
    },
    visible(val) {
      if (val) {
        this.isSetSubName = localStorage.getItem('adset-copy-set-sub-name') == 1 ? true : false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.saleType {
  line-height: 1.5;
  margin: 8px 0;
}
.el-main {
  height: calc(100vh - 140px);
  overflow-y: auto;
}
.copy-ad-series-container {
  .ad-series-wrapper {
    margin-bottom: 20px;
    .ad-series-title {
      background: rgb(230, 241, 255);
      color: rgb(34, 135, 236);
      line-height: 30px;
      padding: 0px 10px;
      display: flex;
    }
    .ad-series-content {
      max-height: 300px;
      overflow: auto;
      border-width: 0px 1px 1px;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;
      border-right-color: rgb(229, 230, 233);
      border-bottom-color: rgb(229, 230, 233);
      border-left-color: rgb(229, 230, 233);
      border-image: initial;
      border-top-style: initial;
      border-top-color: initial;
      padding: 10px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .el-select-dropdown__item {
    height: 60px;
    .account-id {
      font-size: 12px;
      margin-left: 10px;
      line-height: 20px;
      color: #bdbcbc;
      font-weight: 300;
    }
  }
}
</style>
<style lang="scss">
.el-select-dropdown {
  max-width: 400px;
}
.resultMessage {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    &.success {
      background: #67c23a;
    }
    &.warn {
      background: #e6a23c;
    }
    &.success {
      background: #f56c6c;
    }
  }
}
.copy-ad-series-container {
  .ad-series-wrapper {
    .ad-series-content {
      .el-checkbox {
        width: 100%;
        .el-checkbox__label {
          width: 98%;
          .ad-series-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            .name {
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .name:hover {
              background: rgb(230, 241, 255);
            }
            .status {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 10%;
            }
          }
        }
      }
    }
  }
  .el-loading-spinner {
    margin-top: -14px;
    .circular {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
